import styled, { keyframes } from "styled-components";


export const PurpleContainer = styled.div`
  padding: 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
`;

export const PurpleWrapper = styled.div`
  background: linear-gradient(317.36deg, #f64282 19.95%, #006dff 76.03%);
  /* padding: 80px 80px 68px 80px; */
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 12;
  gap: 60px;
  /* margin-top: 150px; */
  /* max-width: 1290px; */
  width: 100%;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    gap: 50px;
    padding: 50px 24px;
  }

`;

export const TextInPurple = styled.p`
  margin: 0;

  /* Desk/H2 */
  font-family: "Cabin";
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 110%;
  /* or 57px */
  text-align: center;

  color: #f5f5f5;

  text-rendering: geometricPrecision;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }

  @media only screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 40px;
  }
`;

export const SpanTextInPurple = styled.span`
  margin: 0;
  color: #fff;
  line-height: 57px;
  letter-spacing: -1.44px;
  font-size: 48px;
  font-family: "Roboto", serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-style: italic;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }

  @media only screen and (max-width: 450px) {
    font-size: 38px;
    line-height: 40px;
  }
`;

export const ButtonInPurple = styled.button`
  border: 1px solid #ffffff;
  border-radius: 16px;
  background: transparent;
  /* COMMON/BUTTON */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  text-align: center;
  letter-spacing: -0.3px;

  color: #ffffff;

  /* width: max-content; */
  /* min-width: 500px; */
  /* width: 40%; */
  padding: 0px 32px;

  height: 48px;
  position: relative;
  cursor: pointer;
  z-index: 10;
  &:hover {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #fff;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;




const ellipse1 = keyframes`
0% {
    transform: translate(-50%, 55%) scale(0.8);
    opacity: 0;
}
20% {
    transform: translate(-50%, 55%) scale(0.9);
    opacity: 1;
}
100% {
    transform: translate(-50%, 55%) scale(1);
    opacity: 0;
}
`;

export const Elipse1 = styled.div`
  width: 200px;
  height: 200px;
  animation: ${ellipse1} 3s linear 0s infinite;

  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate(-50%, 55%);
  z-index: 0;
`;
export const Elipse2 = styled.div`
  width: 290px;
  height: 290px;
  animation: ${ellipse1} 3s linear 0s infinite;
  animation-delay: 0.5s;

  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate(-50%, 55%);
  z-index: 0;
`;
export const Elipse3 = styled.div`
  width: 394px;
  height: 394px;
  animation: ${ellipse1} 3s linear 0s infinite;
  animation-delay: 1s;
  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate(-50%, 55%);
  z-index: 0;
`;
export const Elipse4 = styled.div`
  width: 496px;
  height: 496px;
  animation: ${ellipse1} 3s linear 0s infinite;
  animation-delay: 1.5s;

  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate(-50%, 55%);
  z-index: 0;
`;