import styled, { keyframes } from "styled-components";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
  padding-bottom: 96px;
  background-color: #1e232e;
  gap: 96px;
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
    gap: 75px;
  }

  @media only screen and (max-width: 450px) {
    padding: 96px 24px 75px 24px;
    /* gap: 96px; */
  }
`;

export const Row = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
  gap: 48px;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-width: 1280px;
  @media only screen and (max-width: 820px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
`;

export const CardsAnimatedContainer = styled.div`
  min-height: 206px;
  min-width: 570px;
  width: 100%;
  max-width: 92%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 1200px) {
    max-width: 90%;
  }
  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 450px) {
    min-width: 100%;
    flex-direction: column;
    gap: 20px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  @media only screen and (max-width: 820px) {
    flex-direction: row;
    /* gap: 0; */
  }
`;

export const Bullet = styled.div`
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  &.active {
    background-color: #7f56d9; /* Cambia el color de fondo según sea necesario */
  }
`;

export const AnimatedCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  gap: 16px;

  width: 550px;
  height: 237px;
  box-sizing: border-box;

  background: linear-gradient(
    210.47deg,
    rgba(255, 255, 255, 0.1) 1.9%,
    rgba(241, 241, 241, 0.1) 35.82%,
    rgba(153, 153, 153, 0.1) 69.97%
  );
  backdrop-filter: blur(12px);
  border-radius: 16px;

  @media only screen and (max-width: 1025px) {
    width: 100%;
    height: 100%;
  }
`;

export const AnimatedCardTitle = styled.h3`
font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 39px;
display: flex;
align-items: center;
  color: #f64282;
  margin: 0;
`;

export const AnimatedCardText = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 130%;
letter-spacing: -0.3px;

  color: #ffffff;
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 110%;
  }
`;

export const AnimatedCaptionText = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 130%;
letter-spacing: -0.3px;

  color: #ffffff;
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 110%;
  }
`;

export const IconCardMain = styled.img`
  /* width: 42px; */
  /* height: 42px; */
  position: absolute;
  height: 95%;
  top: 0;
  right: -85px;
  opacity: 0.2;

  @media only screen and (max-width: 768px) {
    height: 90%;
  }
  @media only screen and (max-width: 450px) {
    height: 80%;
    top: 10px;
  }
  @media only screen and (max-width: 410px) {
    height: 70%;
    top: 10px;
  }
`;

export const Title = styled.h2`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  background: linear-gradient(142.43deg, #f64282 37.38%, #006dff 95.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  margin: 0;
  @media only screen and (max-width: 768px) {
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  @media only screen and (max-width: 450px) {
    font-size: 28px;
    line-height: 110%;
  }
`;

export const SpanText = styled.p`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  color: #ffffff;
  margin: 0;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 110%;
  }
`;

export const ContainerBackground = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
  @media only screen and (max-width: 450px) {
    background: none;
    padding-bottom: 0;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 234px;
  @media only screen and (max-width: 1260px) {
    height: auto;
  }

  @media only screen and (max-width: 450px) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
`;

export const Card = styled.div`
  /* border: 1px solid #fff; */
  padding: 24px;
  border-radius: 16px;
  background: linear-gradient(
    317.36deg,
    rgba(246, 66, 130, 0.5) 19.95%,
    rgba(0, 109, 255, 0.5) 76.03%
  );
  position: relative;
  transition: border 0.3s ease, box-shadow 0.3s ease;
  max-width: 300px;
  min-width: 400px;
  height: 100%;
  backdrop-filter: blur(12px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    min-height: 250px;
  }

  @media only screen and (max-width: 450px) {
    width: 100%;
    min-width: auto;
    height: auto;
    min-height: 210px;
    max-width: 100%;
    padding: 30px 20px 30px 20px;
    &:nth-of-type(3) {
      margin-bottom: 0;
    }
  }
`;

export const TitleCard = styled.h3`
  font-family: "Roboto", serif;
  color: #ffffff;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 40px;
  line-height: 48.41px;
  letter-spacing: -0.72px;
  margin-bottom: 8px;
`;

export const TextCard = styled.p`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

export const WrapperContentCarrouselCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 48px;
  margin: 24px 0;
  @media only screen and (max-width: 960px) {
    gap: 20px;
    flex-direction: column-reverse;
  }
`;

export const ImagePlace = styled.img`
  max-width: 550px;
  max-height: 206px;
  object-fit: cover; /* Mantiene la proporción y recorta la imagen si es necesario */
  display: block;
`;
