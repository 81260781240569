/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import LogoFooter from "../Assets/Imgs/LogoFooter.svg";
import LinkedinImage from "../Assets/Imgs/linkedin.svg";
import InstagramImg from "../Assets/Imgs/instagram.svg";
import LogoCoru from "../Assets/Imgs/coruCaas.svg";

import {
  Wrapper,
  LogoContainer,
  ColumnCoru,
  Logo,
  Linkedin,
  SocialContainer,
  ItemText,
  Container,
  BottomContainer,
  Text,
} from "./styles.jsx";

const Footer = ({
  i2,
  i3,
  i4,
  footer_dchos,
  footer_sig,
  selectLang,
}) => {
  // Estado inicial para la URL de "Contáctanos" en WhatsApp
  const [urlcontactanos, seturlcontactanos] = useState(
    `https://api.whatsapp.com/send?phone=+525613953649&text=Cómo puedo ponerme en contacto con ustedes?`
  );
  // Estado inicial para la URL de "Nosotros" en WhatsApp

  const [urlnosotros, seturlnosotros] = useState(
    `https://api.whatsapp.com/send?phone=+525613953649&text=¡Hola!, ¿Qué es Coru?`
  );
  // Estado inicial para la URL de "Soporte" en WhatsApp

  const [urlsoporte, seturlsoporte] = useState(
    `https://api.whatsapp.com/send?phone=+525613953649&text=¿Cómo puedo obtener ayuda para mi servicio?`
  );
  // Estado inicial para la URL de LinkedIn

  const [urlLinkedin, seturlLinkedin] = useState(
    "https://www.linkedin.com/company/coru-br/"
  );

  // useEffect para actualizar las URLs según el idioma seleccionado

  useEffect(() => {
    switch (selectLang) {
      case 1:
        // URLs en español
        seturlcontactanos(
          `https://api.whatsapp.com/send?phone=+525613953649&text=¿Cómo puedo ponerme en contacto con ustedes?`
        );
        seturlnosotros(
          `https://api.whatsapp.com/send?phone=+525613953649&text=¡Hola!, ¿Qué es Coru?`
        );
        seturlsoporte(
          `https://api.whatsapp.com/send?phone=+525613953649&text=¿Cómo puedo obtener ayuda para mi servicio? Tengo una duda o problema, ¿en donde puedo contactarlos?`
        );
        seturlLinkedin("https://www.linkedin.com/company/coru-com");
        break;
      case 2:
          // URLs en inglés
        seturlcontactanos(
          `https://api.whatsapp.com/send?phone=+525613953649&text=How can I get in touch with you?`
        );
        seturlnosotros(
          `https://api.whatsapp.com/send?phone=+525613953649&text=Hello! What is Coru?`
        );
        seturlsoporte(
          `https://api.whatsapp.com/send?phone=+525613953649&text=How can I get assistance for my service? If I have a question or problem, where can I contact you?`
        );
        seturlLinkedin("https://www.linkedin.com/company/coru-com");
        break;
      case 3:
          // URLs en portugués
        seturlcontactanos(
          `https://api.whatsapp.com/send?phone=+5511997124616&text=Como posso entrar em contato com vocês?`
        );
        seturlnosotros(
          `https://api.whatsapp.com/send?phone=+5511997124616&text=Olá! O que é o Coru?`
        );
        seturlsoporte(
          `https://api.whatsapp.com/send?phone=+5511997124616&text=Como posso obter ajuda com o meu serviço? Se eu tiver uma dúvida ou problema, onde posso entrar em contato com vocês?`
        );
        seturlLinkedin("https://www.linkedin.com/company/coru-br");
        break;
      default:
        break;
    }
  }, [selectLang]);

  return (
    <Wrapper>
      <Container>
        <LogoContainer>
          <Logo src={LogoCoru} draggable={false} alt="" />
        </LogoContainer>
        <ColumnCoru>
          <ItemText target="_blanck" href={urlnosotros}>
            {i2}
          </ItemText>
          <ItemText target="_blanck" href={urlcontactanos}>
            {i3}
          </ItemText>
          <ItemText target="_blanck" href={urlsoporte}>
            {i4}
          </ItemText>
          <ItemText target="_blanck" href=''>
            FAQ
          </ItemText>
          <ItemText target="_blanck" href=''>
            Casos de uso
          </ItemText>
        </ColumnCoru>
      </Container>
      <BottomContainer>
        <Text
          style={{
            width: "100%",
          }}
        >
          {footer_dchos}
        </Text>
        <SocialContainer>
          <Text>{footer_sig}</Text>
          <Linkedin
            src={LinkedinImage}
            alt=""
            draggable={false}
            onClick={() => window.open(urlLinkedin)}
          />
        </SocialContainer>
      </BottomContainer>
    </Wrapper>
  );
};

export default Footer;
