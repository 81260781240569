import styled, { keyframes, css } from "styled-components";

const slideDown = keyframes`
  from {
    top: -105px;
  }
  to {
    top: 0;
  }
`;

const slideUp = keyframes`
  from {
    top: 0;
  }
  to {
    top: -105px;
  }
`;
export const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  height: 80px;
  /* padding: 29px 52px 31px 70px; */
  padding: 0 24px;
  display: flex;
  align-items: "center";
  width: 100%;
  position: relative;
  z-index: 999999999999;
  @media only screen and (max-width: 450px) {
    padding: 0 24px;
  }
`;

// export const Container = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: calc(100% - 48px);
//   /* max-width: 1440px; */
//   background-color: rgba(255, 255, 255, 1);
//   position: fixed;
//   /* margin: auto 0; */
//   height: 80px;
//   ${(props) =>
//     props.isFixed
//       ? css`
//           z-index: 999999999999;
//           position: fixed;
//           height: 80px;
//           left: 0;
//           top: 0;
//           padding: 0 24px;
//           animation: ${slideDown} 0.5s ease-in-out;
//           width: 100%;
//           @media only screen and (max-width: 450px) {
//             padding: 0 24px;
//           }
//         `
//       : css`
//           animation: ${slideDown} 1s ease-in-out;
//         `}
// `;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  ${props => props.isFixed ? css`
    zIndex: 999999999999;
    position: fixed;
    height: 80px;
    left: 0;
    top: 0;
    padding: 0 24px;
    animation: ${slideDown} 0.5s ease-in-out;
    @media only screen and (max-width: 450px) {
      padding: 0 24px;
    }
  ` : css`
  /* position: fixed; */
  height: 80px;
  width: 100%;
  /* padding: 0 24px; */

  left: 0px;
    /* animation: ${slideUp} 0.5s ease-in-out; */
  `}

 
`;
export const LogoContainer = styled.div``;

export const Logo = styled.img`
  width: 100px;
  @media only screen and (max-width: 450px) {
    width: 90px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  gap: 50px;

  @media only screen and (max-width: 945px) {
    display: none;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  gap: 48px;
`;

export const Item = styled.div`
  font-family: "Roboto", serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 1px;
  color: #464646;
  position: relative;
  cursor: pointer;
  margin: auto 0;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #464646;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: left;
  }

  &:not(:hover)::after {
    transform: scaleX(0);
    transform-origin: right;
  }
`;

export const LenguageContainer = styled.div`
  @media only screen and (max-width: 945px) {
    display: none;
  }
`;
export const MenuContainer = styled.div`
  display: none;

  @media only screen and (max-width: 945px) {
    display: block;
  }
`;

export const LenguageIcon = styled.img`
  cursor: pointer;
`;
