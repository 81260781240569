import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  background: #1e232e;
  padding: 75px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: 1200px) {
    padding: 75px 0;

  }

  @media only screen and (max-width: 450px) {
    padding: 75px 0;
  }
`;

export const Caption = styled.p`
  margin: 0;

  font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
/* identical to box height, or 21px */
display: flex;
align-items: center;
letter-spacing: -0.3px;

  /* PRIMARY/PINK */
  color: #f64282;

  @media only screen and (max-width: 450px) {
    font-size: 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 88px;
  justify-content: center;
  max-width: 1280px;
  min-height: 475px;
  padding: 50px 100px;
  border-radius: 16px;
  background: linear-gradient(357deg, #41464F -2.89%, #434851 21.88%, #1E232E 56.73%);


  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
    gap: 40px;
  }
  @media only screen and (max-width: 960px) {
    gap: 70px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  @media only screen and (max-width: 450px) {
    padding: 24px;
  }
`;

export const LeftContent = styled.div`
  /* width: 50%; */
  /* flex-grow: 1; */
  max-width: 617px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* padding: 24px; */

  @media only screen and (max-width: 768px) {
    width: 100%;
    gap: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 28px;
  flex-direction: column;
`;

export const RightContent = styled.div`
  /* width: 50%; */
  display: flex;
  justify-content: center;
  /* flex-grow: 1; */
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ImageDiv = styled.div`
  position: relative;
  width: 100%;
  min-width: 300px;
  @media only screen and (max-width: 1200px) {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    width: 70%;
    justify-content: center;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 550px;
`;

export const Subtitle = styled.p`
  margin: 0;

  font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 52px;
line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  color: #f5f5f5;

  display: block;
  @media only screen and (max-width: 450px) {
    font-size: 28px;
    line-height: 110%;
    /* text-align: center; */
  }
`;

export const SpanText = styled.span`
  margin: 0;
  /* La mejor experiencia digital */

  /* Desk/H3 */
  font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 52px;
line-height: 120%;
letter-spacing: -0.3px;

  background: linear-gradient(317.36deg, #f64282 19.95%, #006dff 76.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media only screen and (max-width: 450px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Text = styled.p`
  margin: 0;

  letter-spacing: -0.3px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 130%;
display: flex;
align-items: center;

color: rgba(255, 255, 255, 0.8);

@media only screen and (max-width: 768px) {
  font-size: 16px;
}

`;

export const ChipsWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 10px;

  @media only screen and (max-width: 768px) {
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

export const Chip = styled.div`
  display: flex;
  align-items: center;
  padding: 8px auto;
  gap: 16px;
  border: 1px solid #707070;
  border-radius: 8px;
  background: transparent;

  margin-bottom: 0;
  font-family: "Roboto", serif;
  width: 100%;
  height: 48px;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ImageChip = styled.img`
  width: 15px;
  height: 15px;
`;

export const TextChip = styled.p`
  margin: 0;

  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 130%;
letter-spacing: -0.3px;

  color: #d9d9d9;
  /* text-wrap-mode: nowrap; */
`;

const upDownAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px); /* Ajusta este valor según sea necesario */
  }
`;

const rightLeftAnimation = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px); /* Ajusta este valor según sea necesario */
  }
`;

export const SquareGray = styled.div`
  width: 640px;
  height: 540px;
  border-radius: 25px;
  background-color: #efefef;
  animation: ${upDownAnimation} 6s infinite; /* Ajusta la duración según sea necesario */

  @media only screen and (max-width: 768px) {
    width: calc(640px / 2);
    height: calc(540px / 2);
  }
`;

export const CircleGray = styled.div`
  background-color: #efefef;
  width: 355px;
  height: 355px;
  border: 5px solid #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  bottom: -50px;
  left: -50px;
  animation: ${rightLeftAnimation} 6s infinite;
  @media only screen and (max-width: 768px) {
    width: calc(355px / 2);
    height: calc(355px / 2);
    bottom: -25px;
    left: -25px;
  }
`;




export const BottomBackground = styled.div`
  background-color: #181837;
  position: absolute;
  bottom: 0;
  height: 30%;
  width: 100%;
`;
