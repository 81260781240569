import React from "react";
import {
  AnimatedCaptionText,
  AnimatedCard,
  AnimatedCardTitle,
  AnimatedCardText,
  ImagePlace,
  WrapperContentCarrouselCard,
} from "./styles";

const CardAnimated = ({ caption, title, title2, description, image }) => {
  return (
    <WrapperContentCarrouselCard>
      <ImagePlace src={image} draggable={false} />
      <AnimatedCard>
        <AnimatedCaptionText>{caption}</AnimatedCaptionText>
        <div>
          <AnimatedCardTitle>{title}</AnimatedCardTitle>
          <AnimatedCardTitle>{title2}</AnimatedCardTitle>
        </div>
        <AnimatedCardText>{description}</AnimatedCardText>
      </AnimatedCard>
    </WrapperContentCarrouselCard>
  );
};

export default CardAnimated;
