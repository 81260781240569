import React, { useState, useEffect } from 'react'
import Carousel from "react-elastic-carousel";
import { CardsAnimatedContainer, PaginationContainer, Bullet} from './styles';
import CardAnimated from "./CardAnimated";
import TT from "../../Assets/Imgs/tt2.gif";
import ST from "../../Assets/Imgs/speechTt.gif";
import SV from "../../Assets/Imgs/SyntheticV.gif";


const LoopCarrousel = ({
    esc_card_1_cap,
    esc_card_1_text,
    esc_card_2_cap,
    esc_card_2_text,
    esc_card_3_cap,
    esc_card_3_text,
    esc_card_4_cap,
    esc_card_4_text,
}) => {
    const [auxRefetch, setAuxRefecth] = useState(false)
    // const [secondAuxRefetch, setSecondAuxRefetch] = useState(false)
    const carrouselRef = React.useRef(null);
    // const secondCarrouselRef = React.useRef(null);
    const [itemActive, setItemActive] = React.useState(0);

    const animatedCards = [
      {
        id: 0,
        caption: esc_card_2_cap,
        title: "STT",
        title2: "(Speech-to-Text)",
        description: esc_card_2_text,
        image: ST,
      },
      {
        id: 1,
        caption: esc_card_3_cap,
        title: "SV",
        title2: "(Synthetic Voice)",
        description: esc_card_3_text,
        image: SV,
      },
      {
        id: 2,
        caption: esc_card_4_cap,
        title: "TTS",
        title2: "(Text-to-Speech)",
        description: esc_card_4_text,
        image: TT,
      },
    ]
      
  
  return (
    <CardsAnimatedContainer>
    {/* <CardAnimated /> */}
   {/* {auxRefetch && ( */}
     <Carousel
     verticalMode
     itemsToShow={1}
     enableAutoPlay={true}
     showArrows={false}
     pagination={false}
     autoPlaySpeed={8000}
    //  itemPadding={[10, 0, 10, 0]}
     onChange={(currentItem) => {
      setItemActive(currentItem.index);
       if (currentItem.index === 2) {
         console.log('entrs')
         setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          carrouselRef.current?.goTo(0);
          // setSecondAuxRefetch(true);
          // setAuxRefecth(false);
         }, 8000);
       }
       }}
       style={{
       backgroundColor: "transparent",
      //  gap: "10px",
      //  display: auxRefetch ? "block" : "none",
     }}
     ref={carrouselRef}
   >
     {animatedCards.map((card) => (
       <CardAnimated
         key={card.id}
         caption={card.caption}
         title={card.title}
         title2={card.title2}
         description={card.description}
         image={card.image}
       />
     ))}
   </Carousel>
  {/* //  )} */}
   {/* {secondAuxRefetch && (
     <Carousel
     verticalMode
     itemsToShow={1}
     enableAutoPlay={secondAuxRefetch}
     showArrows={false}
     pagination={false}
     autoPlaySpeed={3000}
     className='two'
     onChange={(currentItem, ) => {
       if (currentItem.index === 4) {
        console.log('entrs')
        setTimeout(() => {
          setAuxRefecth(true);
          secondCarrouselRef.current?.goTo(0);
         setSecondAuxRefetch(false);
        }, 2000);

       }
     }}
     style={{
       backgroundColor: "transparent",
       display: secondAuxRefetch ? "block" : "none",
     }}
     ref={secondCarrouselRef}
   >
     {animatedCards.map((card) => (
       <CardAnimated
         key={card.id}
         caption={card.caption}
         title={card.title}
         title2={card.title2}
         description={card.description}
       />
     ))}
   </Carousel>
   )} */}
    <PaginationContainer>
      <Bullet
        className={itemActive === 0 ? "active" : ""}
        onClick={() => carrouselRef.current?.goTo(0)}
      />
      <Bullet
        className={itemActive === 1 ? "active" : ""}
        onClick={() => carrouselRef.current?.goTo(1)}
      />
      <Bullet
        className={itemActive === 2 ? "active" : ""}
        onClick={() => carrouselRef.current?.goTo(2)}
      />
      {/* <Bullet
        className={itemActive === 3 ? "active" : ""}
        onClick={() => carrouselRef.current?.goTo(3)}
      /> */}
    </PaginationContainer>
  </CardsAnimatedContainer>
  )
}

export default LoopCarrousel