import styled from "styled-components";

export const Wrapper = styled.div`
  background: #1e232e;
  position: relative;
  overflow: hidden;
`;

export const GradientBackground = styled.div`
  background: linear-gradient(
    345.36deg,
    rgba(246, 66, 130, 0.5) 10.95%,
    rgba(0, 109, 255, 0.5) 76.03%
  );
  backdrop-filter: blur(12px);
  isolation: isolate;
  overflow: hidden;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  overflow: hidden;
  padding: 45px 0px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  z-index: 0;

  @media only screen and (max-width: 1200px) {
    gap: 60px;
  }
  @media only screen and (max-width: 960px) {
    width: 100%;
    height: auto;
    min-height: auto;
    flex-direction: column;
    gap: 50px;
    padding: 50px 24px;
  }
`;
export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  max-width: 589px;

  @media only screen and (max-width: 768px) {
    gap: 20px;
    width: 100%;
  }
`;

export const Title = styled.h1`
  margin: 0;
  /* Desk/H3 */
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;
  /* identical to box height, or 53px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.3px;

  color: #F5F5F5;

  @media only screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 110%;
  }
`;

export const Subtitle = styled.h2`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* or 26px */
  display: flex;
  align-items: center;
  text-align: center;

  color: #D9D9D9;

  margin: 0;
`;

export const Image = styled.img`
  width: 305px;
  height: 294px;
  position: relative;
  z-index: 10;
  @media only screen and (max-width: 768px) {
    width: 265px;
    height: 254px;
  }
`;

export const BG = styled.img`
  position: absolute;
  z-index: 1;
  width: 500px;
  height: 500px;
  left: -120px;
  top: -50px;

  @media only screen and (max-width: 450px) {
    width: 400px;
    height: 400px;
    opacity: 0.4;
  }
`;

export const BG2 = styled.img`
  position: absolute;
  z-index: 1;
  width: 500px;
  height: 500px;
  /* left: 1139px; */
  right: -110px;
  top: -50px;
  @media only screen and (max-width: 960px) {
    top: unset;
    bottom: -50px;
    /* opacity: 0.8; */

  }

  @media only screen and (max-width: 450px) {
    width: 400px;
    height: 400px;
  }
`;
export const ButtonInPurple = styled.button`
  border: 1px solid #ffffff;
  border-radius: 16px;
  background: transparent;
  /* COMMON/BUTTON */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  text-align: center;
  letter-spacing: -0.3px;

  color: #ffffff;

  /* width: max-content; */
  /* min-width: 500px; */
  /* width: 40%; */
  padding: 0px 32px;

  height: 48px;
  position: relative;
  cursor: pointer;
  z-index: 10;
  &:hover {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #fff;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
