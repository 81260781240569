import React, { useState, useEffect, useRef } from 'react'
import {
    Wrapper,
    Container,
    LogoContainer,
    Logo,
    RightContainer,
    ItemsContainer,
    Item,
    LenguageContainer,
    LenguageIcon,
    MenuContainer,
} from './styles'
import LogoWhite from '../../Assets/Imgs/logo-white.svg'
import Language from '../../Assets/Imgs/Language.svg'
import useFixedOnScroll from '../../hooks/useFixedOnScroll'
import LanguageMenu from '../../LanguageMenu'
import Burguer from '../../Assets/Imgs/Menu.svg'
import NavBarMobil from './NavBarMobil'

// Componente NavBar
const NavBar = ({ url, ct, cu, pf }) => {
    // Estado para controlar la apertura del menú de idiomas
    const [openLanguage, setOpenLanguage] = useState(false);
    // Estado para controlar la apertura del menú móvil
    const [openMenuMobile, setOpenMenuMobile] = useState(false);
    // Referencia para el menú de idiomas
    const languageMenuRef = useRef(null);
    // Hook personalizado para fijar el navbar al hacer scroll
    const isFixed = useFixedOnScroll(50);

    // Función para manejar la apertura/cierre del menú de idiomas
    const handleLangMenu = () => {
        setOpenLanguage(!openLanguage);
    };

    // Efecto para manejar el redimensionamiento de la ventana
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 945) {
                setOpenLanguage(false);
            }
        };

        // Agregar el event listener para el redimensionamiento
        window.addEventListener('resize', handleResize);

        // Verificar el tamaño inicial de la ventana
        handleResize();

        // Limpiar el event listener al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Efecto para manejar clics fuera del menú de idiomas
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
                setOpenLanguage(false);
            }
        };

        // Agregar el event listener para clics fuera del menú
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Limpiar el event listener al desmontar el componente
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [languageMenuRef]);

    return (
        <>
            <Wrapper>
                <Container isFixed={isFixed}>
                    <LogoContainer>
                        <Logo src={LogoWhite} draggable={false} />
                    </LogoContainer>
                    <RightContainer>
                        <ItemsContainer>
                            {/* <Item>Home</Item>
                            <Item>Quem Somos</Item>
                            <Item>Como Funciona</Item> */}
                            <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', margin: 'auto 0' }} >
                                <Item>{ct}</Item>
                            </a>
                            <Item>{cu}</Item>

                            <Item>{pf}</Item>

                        </ItemsContainer>
                    </RightContainer>
                    <LenguageContainer>
                        <LenguageIcon src={Language} draggable={false} onClick={handleLangMenu} />
                    </LenguageContainer>
                    {openLanguage && (
                        <div ref={languageMenuRef}
                            style={{
                                position: 'absolute',
                                top: '0px',
                                right: '0',
                                width: '100%',
                            }}
                        >
                            <LanguageMenu />
                        </div>
                    )}
                    <MenuContainer>
                        <LenguageIcon src={Burguer} draggable={false} onClick={() => setOpenMenuMobile(true)} />
                    </MenuContainer>
                </Container>
            </Wrapper>
            <NavBarMobil url={url} open={openMenuMobile} closeMenu={() => setOpenMenuMobile(false)} />
        </>
    )
}

export default NavBar