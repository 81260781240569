import React from "react";
import Card from "./Card";
import { Title, Wrapper, Divider, Header, CardsContainer } from "./styles";

const Agroindustrial = ({
  card,
}) => {
    // se agrega  &nbsp; segui del texto apra agregar un espacio en el texto,
    // por algua razon no se puede agregar directamente

  return (
    <Wrapper>
      <Header>
        <Title>{card.title}</Title>
        <Divider />
      </Header>
      <CardsContainer>
        <Card number="70" type="%" text={card.card1.text} />
        <Card number="10&nbsp;" type={card.card2.type} text={card.card2.text} />
        <Card number="1" type="%" text={card.card3.text} />
      </CardsContainer>
    </Wrapper>
  );
};

export default Agroindustrial;
