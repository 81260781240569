import React from "react";
import Card from "./Card";
import { Title, Wrapper, Divider, Header, CardsContainer } from "./styles";

const Financiero = ({ card }) => {
    // se agrega  &nbsp; segui del texto apra agregar un espacio en el texto,
    // por algua razon no se puede agregar directamente

  return (
    <Wrapper>
      <Header>
        <Title>{card.title}</Title>
        <Divider />
      </Header>
      <CardsContainer>
        <Card number="75" type="%" text={card.card1.text}/>
        <Card number="70" type="%" text={card.card2.text} extraText={card.card2.extraText} />
        <Card number="9&nbsp;" type={card.card3.type} text={card.card3.text} />
        <Card number="0" type="%" text={card.card4.text} />
      </CardsContainer>
    </Wrapper>
  );
};

export default Financiero;
