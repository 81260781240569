import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  z-index: 999999;
  min-height: 268px;
  min-height: 550px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  gap: 48px;

  @media only screen and (max-width: 1200px) {
    width: 90%;
  }
  /* @media only screen and (max-width: 450px) {} */
  @media only screen and (max-width: 768px) {
    /* width: 100%; */
    width: unset;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18.5px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  /* DESKTOP/H2 */
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;
  /* or 62px */
  text-align: center;
  letter-spacing: -0.3px;
  text-rendering: geometricPrecision;
  color: #979dad;
  margin: 0;

  @media only screen and (max-width: 768px) {
    /* font-size: 24px; */
    padding-top: 30px;
    font-size: 28px;
    line-height: 110%;
  }
`;

export const Divider = styled.span`
  height: 4.96px;
  width: 768px;
  background: linear-gradient(90deg, #f64282 0%, #016dff 100%);

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 14.6796px 29.3592px; */
  gap: 9.79px;

  width: 252px;
  height: 154px;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    linear-gradient(
      248.94deg,
      rgba(255, 255, 255, 0.4) -18.68%,
      rgba(241, 241, 241, 0.4) 21.59%,
      rgba(153, 153, 153, 0.4) 63.15%
    );
  border-radius: 14.6796px;

  @media only screen and (max-width: 768px) {
    width: calc(100vw - 48px);
    height: auto;
    min-height: 146px;
    /* width: 100%; */
    padding: 24px;
  }
`;

export const NumberCard = styled.p`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 61.0348px;
  line-height: 100%;
  /* identical to box height, or 61px */
  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #ffffff;
  margin: 0;
  text-rendering: geometricPrecision;

  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
`;

export const NumberCardType = styled.span`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 33.5691px;
  line-height: 40.4px;
  /* or 34px */
  display: flex;
  align-items: center;
  text-align: center;
  text-rendering: geometricPrecision;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const TextCard = styled.p`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  /* or 24px */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-rendering: geometricPrecision;

  color: #ffffff;
  margin: 0;
`;

export const ExtraTextCard = styled.p`
  margin: 0;

  font-family: 'Cabin';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 150%;
/* or 27px */
display: flex;
align-items: center;
text-align: center;

color: #FFFFFF;
text-rendering: geometricPrecision;
`


export const CardsContainer = styled.div`
    display: flex;
    justify-content: space-between;

    /* height: 278px;
  max-height: 278px; */
  min-width: 1060px;

  @media only screen and (max-width: 1200px) {
    min-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }

  @media only screen and (max-width: 768px) {
   flex-direction: column;
   min-width: unset;
   align-items: center;
   width: 100%;
  }
`

// cartao

export const Chip = styled.img`
    width: 298.68px;
    height: 128.48px;
`

export const GradientCartao = styled.div`
/* Frame 1343 */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 19.8321px 39.6642px;
gap: 13.22px;

width: 718px;
height: 122.66px;

background: linear-gradient(109.33deg, #016DFF 5.01%, #F64282 104.75%);
border-radius: 19.8321px;

@media only screen and (max-width: 768px) {
  width: calc(100vw - 48px);
    height: auto;
    min-height: 146px;
    padding: 24px;
    flex-direction: column;
}
`

export const TitleCartao = styled.h1`
font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 82.6337px;
line-height: 100%;
/* identical to box height, or 83px */
display: flex;
align-items: flex-end;
text-align: center;
margin: 0;
text-rendering: geometricPrecision;

color: #FFFFFF;

@media only screen and (max-width: 768px) {
  font-size: 52px;
}
`

export const PercentageCartao = styled.span`
font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 45.4485px;
line-height: 122%;
/* or 45px */
display: flex;
align-items: center;
text-align: center;
color: #FFFFFF;
margin: 0;
text-rendering: geometricPrecision;

@media only screen and (max-width: 768px) {
  font-size: 30px;
}

`

export const DescripcionCartao = styled.p`
width: 288.42px;
height: 64px;
font-family: 'Cabin';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 100%;
/* or 32px */
display: flex;
align-items: center;
text-align: center;
margin: 0;
/* min-width: 274px;
max-width: 288.42px; */
color: #FFFFFF;
word-break: normal; /* Evita que las palabras se corten */
  overflow-wrap: normal; /* No permite dividir palabras */
  white-space: normal; /* Permite varias líneas, pero sin cortar palabras */
text-rendering: geometricPrecision;

@media only screen and (max-width: 768px) {
  font-size: 24px;
  /* width: 100%; */
}
`