import React from "react";
import Card from "./Card";
import {
  Wrapper,
  Divider,
  Header,
  CardsContainer,
  Chip,
  DescripcionCartao,
  TitleCartao,
  PercentageCartao,
  GradientCartao,
} from "./styles";
import CartaoChip from "../../../Assets/Imgs/casos/cartaoChip.svg";

const Cartao = ({ card }) => {
  // se agrega  &nbsp; segui del texto apra agregar un espacio en el texto,
  // por algua razon no se puede agregar directamente

  return (
    <Wrapper>
      <Header style={{ gap: 0 }}>
        <Chip src={CartaoChip} />
        <Divider />
      </Header>
      <GradientCartao>
        <TitleCartao>
          +34
          <PercentageCartao>%</PercentageCartao>
        </TitleCartao>
        <DescripcionCartao>{card.gradiendText}</DescripcionCartao>
      </GradientCartao>
      <CardsContainer>
        <Card number="71" type="%" text={card.card1.text} />
        <Card number="23" type="%" text={card.card2.text ? card.card2.text.replace(/-/g, '\u2011') : ""} />
        <Card
          number="8,5&nbsp;"
          type={card.card3.type}
          text={card.card3.text}
        />
        <Card
        number="5&nbsp;"
        type={card.card4.type}
        text={card.card4.text}
        />
      </CardsContainer>
    </Wrapper>
  );
};

export default Cartao;
