import React from "react";
import Go from '../../../components/CaasHomeNew/Assets/Imgs/partners/2Go.png';
// import Arcelor from '../../../components/CaasHomeNew/Assets/Imgs/partners/ArcelorMittal.svg';
// import Baz from '../../../components/CaasHomeNew/Assets/Imgs/partners/Baz.svg';
import Cartao from '../../../components/CaasHomeNew/Assets/Imgs/partners/Cartao.png';
import CMI from '../../../components/CaasHomeNew/Assets/Imgs/partners/CMI.png';
import Furukawa from '../../../components/CaasHomeNew/Assets/Imgs/partners/furukawa.png';
import Fs from '../../../components/CaasHomeNew/Assets/Imgs/partners/Fs.png';
import GrupoTrigo from '../../../components/CaasHomeNew/Assets/Imgs/partners/GrupoTrigo.png';
import Keune from '../../../components/CaasHomeNew/Assets/Imgs/partners/Keune.png';
import Loft from '../../../components/CaasHomeNew/Assets/Imgs/partners/Loft.png';
import MercadoBitcoin from '../../../components/CaasHomeNew/Assets/Imgs/partners/MercadoBitcoin.png';
import Pagbank from '../../../components/CaasHomeNew/Assets/Imgs/partners/PagBank.png';
// import Rentokil from '../../../components/CaasHomeNew/Assets/Imgs/partners/Rentokil.svg';
import Wantel from '../../../components/CaasHomeNew/Assets/Imgs/partners/Wantel.png';
// import azteca from '../../../components/CaasHomeNew/Assets/Imgs/partners/azteca.png';
import Slider from "./Slider";
import {SwiperWrapper} from './styles';

const Partners = () => {
    const images1 = [
        Go, //1
        // azteca, //1
        // Arcelor, //1
         CMI, //0
        Cartao, //1
        Fs, //0
        Furukawa, // 1
        GrupoTrigo, // 0
        Keune, // 0
        Loft, //1
        MercadoBitcoin, //1
        Pagbank,//1
        // Rentokil, //1
        Wantel, //1
      ];
    return (
        <SwiperWrapper>
               <Slider images={images1} width={170} height={50} velocity={30} />
        </SwiperWrapper>
      );
    };
    
export default Partners;