import styled from "styled-components";

export const Wrapper = styled.div`
    height: auto;
    width: 100%;
    background: #101828;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 60px 40px;

    @media only screen and (max-width: 960px) {
      padding: 80px 20px;
    }
`;

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 40px;
`

export const Text = styled.p`
    margin: 0;
    text-rendering: geometricPrecision;
    text-align: center;

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 130%;
/* identical to box height, or 21px */
text-align: center;

color: #979DAD;


`
export const LogoContainer = styled.div`
    height: 100%;
    /* max-width: 120px;
    min-width: 100px; */
    display: flex;
    flex-direction: column;

`;

export const BottomContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 960px) {
     flex-direction: column;
     gap: 24px;
    }
`

export const ColumnCoru = styled.div`
    height: 100%;
    display: flex;
    gap: 24px;
    
    @media only screen and (max-width: 450px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;

export const Logo = styled.img`
    max-width: 170px;
    min-width: 120px;
`;

export const SocialContainer = styled.div`
    /* width: 100%; */
    display: flex;
    align-items: center;
    gap: 20px;
    position: absolute;
    right: 0;
    top: -13px;
    @media only screen and (max-width: 960px) {
      position: relative;
      top: unset;
      right: unset;
    }
`;

export const Linkedin = styled.img`
    width: 40px;
    cursor: pointer;
    `;

export const ItemText = styled.a`
margin: 0;

/* Desk/Text Footer */
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 130%;

color: #FFFFFF;

    &:hover{
        color: #595959;
        text-decoration:none;
    }
`;
