import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  background: #1e232e;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  gap: 32px;
  justify-content: center;
  flex-direction: column;
  position: relative;
 

  @media only screen and (max-height: 700px) {
    height: 600px;
    min-height: 600px;
  }

  @media only screen and (max-width: 950px) {
        padding: 50px 24px;
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 50px;
    }

    @media only screen and (max-width: 450px) {
        /* padding: 0px 24px; */
        max-height: unset;
        gap: 0;
        overflow: hidden;
    }

    video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 950px) {
    max-width: 70%;
    align-items: center;
  }

  @media only screen and (max-width: 450px) {
    max-width: 100%;
  }
`;
export const GradientContent = styled.div`
  position: relative;
  width: 100%;
`;

export const TextContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 4;
  max-width: 873px;
`;

export const SpanDouble = styled.span`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 57.2px;
  width: 100%;
  position: absolute;
  top: 5px;
  right: 0px;
  background: linear-gradient(207.36deg, #F64282 19.95%, #006DFF 76.03%)
    text;
    opacity: 0.2;
  -webkit-text-fill-color: transparent;
  text-align: center;
  /* white-space: nowrap; */
  z-index: 3;
  margin: 0px !important;
  letter-spacing: 1.2px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 450px) {
    font-size: 50px;
    line-height: 57.2px;
    white-space: unset;
    display: none;
  }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const TextGradient = styled.p`
  position: relative;
  margin: 0;
  font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 62px;
line-height: 120%;
  text-align: center;
  background: linear-gradient(587.36deg, #F64282 20.95%, #006DFF 136.03%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
  @media (max-width: 950px) {
  }

  @media (max-width: 450px) {
    font-size: 50px;
    line-height: 57.2px;
    ;
  }
`;

export const Text = styled.p`
  /* display: contents; */
  position: relative;
  margin: 0;
  font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 62px;
line-height: 120%;
  color: #d9d9d9;
  text-align: center;

  /* width: 500px; */

  @media (max-width: 950px) {
    /* width: 100%;
    text-align: center; */
  }

  @media (max-width: 450px) {
    font-size: 50px;
    line-height: 57.2px;
    ;
  }
`;

export const Description = styled.p`
  margin: 0;
  font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
text-align: center;

color: #E6E6E6;
  text-align: center;
  position: relative;
  z-index: 4;
  max-width: 640px;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  z-index: 4;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 48px;
  min-height: 48px;

  background: #f64282;
  border-radius: 16px;
  border: none;

  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.3px;
  margin-top: 32px;
  color: #ffffff;

  padding: 20px 30px;
  outline: none;
  &:active {
    border: none;
  }

  &:focus {
    border: none !important;
    outline: none !important;
  }

  @media (max-width: 950px) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  overflow: hidden;
  @media (max-width: 950px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 72.188rem;
  height: 40.625rem;
  position: absolute;
  top: -80px;
  right: -14.687rem;
  object-fit: cover;
  mix-blend-mode: screen;
  z-index: 2;
  margin: 0px !important;
  filter: blur(10px);
  /* background-color: coral; */
  @media (max-width: 1300px) {
    /* padding-left: 50px; */
  }
  @media (max-width: 950px) {
    left: -100px;
    top: -100px; 
     /* width: 100%; */
        /* height: 100%; */
  }

  @media (max-width: 450px) {
    /* height: 400px;
        width: 100%; */
  }
`;
