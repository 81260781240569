import React from "react";
import { Subtitle, Title, Image, Wrapper, BG, BG2, Content, GradientBackground, ButtonInPurple } from "./styles";
import Cb from "../../Assets/Imgs/cb.svg";
import Cb2 from "../../Assets/Imgs/cb2.svg";
import Gif from "../../Assets/Imgs/g.gif";
import ClickButton from "../../../../Helpers/HookHelpers";

const SixSection = ({
  title,
  sub_title,
  card_btn,
  hubspot,
}) => {
  const { actions } = ClickButton();

  const handleClickCoru = (name) => {
    switch (name) {
      case "Starttheconversation":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_Button_iniciarconversacion`,
          collection: "ClickButtonLPCaaS",
        });
        window.open(hubspot, "_blank");
        break;
      case "BottonSeeallProducts":
        actions({
          action: "ClickButtonLPCaaS",
          variables: `Click_BottonSeeallProducts`,
          collection: "ClickButtonLPCaaS",
        });
        window.open(hubspot, "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <GradientBackground>
        <Content>
          <Title>{title}</Title>
        <Subtitle>
          {sub_title}
        </Subtitle>
        <ButtonInPurple
          onClick={() => handleClickCoru("Starttheconversation")}
        >
          {card_btn}
        </ButtonInPurple>
        </Content>
        <Image
          src={Gif}
          draggable="false"
        />
        <BG src={Cb} draggable={false} />
        <BG2 src={Cb2} draggable={false} />
      </GradientBackground>
    </Wrapper>
  );
};

export default SixSection;
