import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import LogoWhite from '../../../Assets/Imgs/coruCaas.svg'
import CloseIcon from "@material-ui/icons/Close";
import Language from '../../../Assets/Imgs/Language-w.svg'
import LanguageMenu from '../../../LanguageMenu';

// Estilos para el contenedor principal del menú móvil
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10000000000;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateX(-100%);

   &.visible {
    transform: translateX(0);
   }
`

// Estilos para el contenedor del menú
const MenuContainer = styled.div`
  background-color: #1e232e;
  padding: 30px;
  width: 90vw;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  &.visible {
    transform: translateX(0);
  }
`

// Estilos para el encabezado del menú
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
`

// Estilos para el logo
const Logo = styled.img`
  width: 25%;

  @media only screen and (max-width: 450px) {
    width: 45%;
  }
`

// Estilos para los elementos del menú
const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: white;
  padding-top: 40px;
`

// Estilos para cada elemento del menú
const MenuItem = styled.div`
  margin: 0;
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing:1px;
  text-rendering: geometricPrecision;
  cursor: pointer;  
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  color: white;
`

// Estilos para el icono de idioma
const IconLanguage = styled.img`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 30px;
  cursor: pointer;
`

// Componente NavBarMobil
const NavBarMobil = ({ url, open, closeMenu }) => {
  // Estado para controlar la visibilidad del menú
  const [visibility, setVisibility] = useState(false) 
  // Estado para controlar la apertura del menú de idiomas
  const [openLanguage, setOpenLanguage] = useState(false);
  // Referencia para el menú de idiomas
  const languageMenuRef = useRef(null);

  // Función para cerrar el menú
  const handleCloseMenu = () => {
    setOpenLanguage(false)
    closeMenu()
  }

  // Efecto para manejar la visibilidad del menú
  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setVisibility(false)
      }, 300)
    } else {
      setVisibility(true)
    }
  }, [open])

  // Efecto para manejar clics fuera del menú de idiomas
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
        setOpenLanguage(false);
      }
    };

    // Agregar el event listener para clics fuera del menú
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Limpiar el event listener al desmontar el componente
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [languageMenuRef]);

  return (
    <Wrapper className={visibility ? 'visible' : ''}>
      <MenuContainer className={open ? 'visible' : ''}>
        <Header>
          <Logo src={LogoWhite} alt='logo' />
          <CloseIcon
            htmlColor="#ffffff"
            style={{ width: "40px", height: "40px", cursor: "pointer" }}
            onClick={handleCloseMenu}
          />
        </Header>
        <MenuItems>
          {/* <MenuItem>Home</MenuItem>
          <MenuItem>Quem Somos</MenuItem>
          <MenuItem>Como Funciona</MenuItem> */}
          <a href={url} target="_blank" rel="noopener noreferrer" >
            <MenuItem>Contacto</MenuItem>
          </a>
          <MenuItem>Casos de Uso</MenuItem>
          <MenuItem>Preguntas frecuentes</MenuItem>

        </MenuItems>
        {openLanguage && (
          <div ref={languageMenuRef}>
            <LanguageMenu
              top="unset"
              bottom="55px"
              right='0px'
              left='55px'
            />
          </div>
        )}
        <IconLanguage src={Language} alt='icon-language' onClick={() => setOpenLanguage(!openLanguage)} />
      </MenuContainer>
    </Wrapper>
  )
}

export default NavBarMobil