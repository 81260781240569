import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Title,
  SpanText,
  IconCardMain,
  ContainerBackground,
  ContainerCards,
  Card,
  TitleCard,
  TextCard,
  ImagePlace,
  CardsAnimatedContainer,
  PaginationContainer,
  Bullet,
  AnimatedCard,
  AnimatedCaptionText,
  AnimatedCardTitle,
  AnimatedCardText,
} from "./styles";
import ic12 from "../../Assets/Imgs/ic1-2.svg";
import ic22 from "../../Assets/Imgs/ic2-2.svg";
import ic32 from "../../Assets/Imgs/ic3-2.svg";
import LoopCarrousel from "./LoopCarrousel";

// Componente ThirdSection
const ThirdSection = ({
  nos_flow_conv,
  nos_flow_conv_desc,
  nos_flow_cent,
  nos_flow_cent_desc,
  nos_flow_ia,
  nos_flow_ia_desc,
  nos_flow_per,
  nos_flow_per_desc,
  esc_tit,
  esc_tit2,
  esc_sat,
  esc_sat_desc,
  esc_disp,
  esc_disp_desc,
  esc_usr,
  esc_usr_desc,
  esc_sub_t,
  esc_card_1_cap,
  esc_card_1_text,
  esc_card_2_cap,
  esc_card_2_text,
  esc_card_3_cap,
  esc_card_3_text,
  esc_card_4_cap,
  esc_card_4_text
}) => {
 
  // const carrouselRef = React.useRef(null);
  // const secondCarrouselRef = React.useRef(null);

  return (
    <Container>
      <ContainerBackground>
        {/* Título de la sección con estilo centrado */}
        <div>
        <Title style={{ textAlign: "center" }}>{esc_tit}</Title>
        <SpanText>{esc_sub_t}</SpanText>
        </div>
        <ContainerCards>
          {/* Tarjeta con icono, título y texto */}
          <Card>
            <IconCardMain src={ic12} draggable={false} />
            <TitleCard>{esc_sat}</TitleCard>
            <TextCard>{esc_sat_desc}</TextCard>
          </Card>

          <Card>
            <IconCardMain src={ic22} draggable={false} />
            <TitleCard>{esc_disp}</TitleCard>
            <TextCard>{esc_disp_desc}</TextCard>
          </Card>
          <Card>
            <IconCardMain src={ic32} draggable={false} />
            <TitleCard>{esc_usr}</TitleCard>
            <TextCard>{esc_usr_desc}</TextCard>
          </Card>
        </ContainerCards>
      </ContainerBackground>
      <Row>
        <LoopCarrousel
          esc_card_1_cap={esc_card_1_cap}
          esc_card_1_text={esc_card_1_text}
          esc_card_2_cap={esc_card_2_cap}
          esc_card_2_text={esc_card_2_text}
          esc_card_3_cap={esc_card_3_cap}
          esc_card_3_text={esc_card_3_text}
          esc_card_4_cap={esc_card_4_cap}
          esc_card_4_text={esc_card_4_text}
        />
      </Row>
    </Container>
  );
};

export default ThirdSection;
