import React, { useRef } from "react";
import {
  WrapperCarrousel,
  PaginationContainer,
  Bullet,
  CustomCarousel,
} from "./styles";
import Agroindustrial from "./carrouselComponents/Agroindustrial";
import Financiero from "./carrouselComponents/Financiero";
import Cartao from "./carrouselComponents/Cartao";

const CarrouselUseCases = ({
  card_1,
  card_2,
  card_3,
}) => {
  const carrouselRef = useRef(null);
  const [itemActive, setItemActive] = React.useState(0);

  return (
    <WrapperCarrousel>
      <CustomCarousel
        ref={carrouselRef}
        itemsToShow={1}
        enableAutoPlay={true}
        pagination={false}
        showArrows={false}
        autoPlaySpeed={8000}
        className={`item${itemActive}`}
        onChange={(currentItem, pageIndex) => {
          setItemActive(currentItem.index);
          if (pageIndex === 2) {
            setTimeout(() => {
              // eslint-disable-next-line no-unused-expressions
              carrouselRef.current?.goTo(0);
            }, 8000);
          }
        }}
      >
        <Agroindustrial card={card_1} />
        <Financiero card={card_2} />
        <Cartao card={card_3} />
      </CustomCarousel>
      <PaginationContainer>
        <Bullet
          className={itemActive === 0 ? "active" : ""}
          onClick={() => carrouselRef.current?.goTo(0)}
        />
        <Bullet
          className={itemActive === 1 ? "active" : ""}
          onClick={() => carrouselRef.current?.goTo(1)}
        />
        <Bullet
          className={itemActive === 2 ? "active" : ""}
          onClick={() => carrouselRef.current?.goTo(2)}
        />
      </PaginationContainer>
    </WrapperCarrousel>
  );
};

export default CarrouselUseCases;
