import React from "react";
import {
  CardContainer,
  TextCard,
  NumberCard,
  NumberCardType,
  ExtraTextCard,
} from "./styles";

const Card = ({ text, number, type, extraText }) => {
  return (
    <CardContainer>
      <NumberCard>
        {number}
        <NumberCardType>{type}</NumberCardType>
      </NumberCard>
      <div style={{
        maxWidth: "96%",
      }}>
        <TextCard>{text}</TextCard>
        {extraText && <ExtraTextCard>{extraText}</ExtraTextCard>}
      </div>
    </CardContainer>
  );
};

export default Card;
