import React from 'react'
import { Title, SecondSectionContainer, SpanText, MiniTitle, TitleContainer } from './styles'
import Partners from '../../Partners'

// Componente SecondSection
const SecondSection = ({
  nos_tit,
  nos_subt1,
  nos_subt2,
  nos_desc
}) => {
  return (
    <SecondSectionContainer>
      <TitleContainer>
        <MiniTitle>{nos_desc}</MiniTitle>
      </TitleContainer>
      {/* Componente Partners con información de clientes */}
      <Partners info="Clientes" />
    </SecondSectionContainer>
  )
}

export default SecondSection