import React from "react";
import { Title, Wrapper, BG } from "./styles";
import DB from "../../Assets/Imgs/DB.svg";
import CarrouselUseCases from "./CarrouselUseCases";

const FiveSection = ({
  title,
  card_1,
  card_2,
  card_3,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <CarrouselUseCases card_1={card_1} card_2={card_2} card_3={card_3} />
      <BG src={DB} draggable={false} />
    </Wrapper>
  );
};

export default FiveSection;
