import styled from "styled-components";

export const SecondSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: #1e232e;
  padding: 48px 0;
  @media only screen and (max-width: 768px) {
    gap: 40px;
  }

  @media only screen and (max-width: 450px) {
    padding: 50px 24px 0;
  }
`;

export const TitleContainer = styled.div`
  /* margin-top: 130px; */

  @media only screen and (max-width: 450px) {
    /* margin-top: 100px; */
  }
`;

export const Title = styled.div`
  color: #030014;
  font-size: 48px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: -1.44px;
  font-family: "Roboto", serif;
  text-rendering: geometricPrecision;
  text-align: center;
  @media (max-width: 834px) {
    margin-top: 100px;
    margin-bottom: 00px;
    font-size: 32px;
    width: 100%;
  }
  @media (max-width: 450px) {
    margin-top: 24px;
    margin-bottom: 24px;
    line-height: 40px;
    display: grid;
  }
`;

export const SpanText = styled.span`
  font-family: "Roboto", serif;
  font-style: normal;
  line-height: 75px;
  letter-spacing: -1.95px;
  width: 100%;
  font-style: italic;
  text-rendering: geometricPrecision;

  display: inline;

  padding-right: 9px;
  margin-right: -8px;
  color: transparent;

  background: linear-gradient(317.36deg, #f64282 19.95%, #006dff 76.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media only screen and (max-width: 450px) {
    padding-right: 0px;
    margin-right: 0px;
    line-height: 40px;
  }
`;

export const MiniTitle = styled.p`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #979dad;
  align-self: stretch;

  text-align: center;
  margin: 0px;
`;
