import React from "react";
// Importing styled components from the styles file
import {
  Wrapper,
  Content,
  LeftContent,
  RightContent,
  Subtitle,
  SpanText,
  Text,
  ChipsWrapper,
  Chip,
  ImageChip,
  TextChip,
  SquareGray,
  CircleGray,
  PurpleWrapper,
  TextInPurple,
  SpanTextInPurple,
  ImageDiv,
  ButtonInPurple,
  PurpleContainer,
  Elipse1,
  Elipse2,
  Elipse3,
  Elipse4,
  Image,
  Caption,
  Header,
  BottomBackground,
} from "./styles";
// Importing images
import check from "../../Assets/Imgs/check-white.svg";
import mejoranps from "../../Assets/Imgs/mejoranps.png";
import mejoranpsEn from "../../Assets/Imgs/mejoranps-en.png";
import mejoranpsPt from "../../Assets/Imgs/mejoranps-pt.png";

// Functional component FourSection
const FourSection = ({
  neg_tit,
  neg_tit2,
  neg_desc,
  neg_btn_red,
  neg_btn_mej,
  neg_btn_aum,
  hubspot,
  caption,
  title_simple,
  title_span,
  text_1,
  text_2,
  lenguage,
}) => {
  const image = (() => {
    // espanol
    if (lenguage === 1) {
      return mejoranps
    }
    //ingles
    if (lenguage === 2) {
      return mejoranpsEn
    }

    // portugues
    if (lenguage === 3) {
      return mejoranpsPt
    }
    
  })()
 
  return (
    // Main wrapper for the section
    <Wrapper>
      <Content>
        {/* Left content section */}
        <LeftContent>
          <Header>
            <Caption>{caption}</Caption>

            {/* Subtitle with span text */}
            <Subtitle>
              {title_simple}
              <SpanText> {title_span}</SpanText>
            </Subtitle>
          </Header>
          {/* Description text */}
          <div>
          <Text>{text_1}</Text>
          <br />
          <Text>{text_2}</Text>
          </div>
          {/* Wrapper for chips */}
          <ChipsWrapper>
            {/* Individual chip with image and text */}
            <Chip>
              <ImageChip src={check} alt="" draggable={false} />
              <TextChip>{neg_btn_red}</TextChip>
            </Chip>
            <Chip>
              <ImageChip src={check} alt="" draggable={false} />
              <TextChip>{neg_btn_mej}</TextChip>
            </Chip>
            <Chip style={{ width: "100%" }}>
              <ImageChip src={check} alt="" draggable={false} />
              <TextChip>{neg_btn_aum}</TextChip>
            </Chip>
          </ChipsWrapper>
        </LeftContent>
        {/* Right content section */}
        <RightContent>
          {/* Image container */}
          <ImageDiv>
            <Image src={image} alt="" draggable={false} />        
          </ImageDiv>
        </RightContent>
      </Content>
     
    </Wrapper>
  );
};

// Exporting the component as default
export default FourSection;
