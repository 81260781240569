import styled from "styled-components";

export const Title = styled.div`
    font-family: 'Cabin';
    font-weight: 600;
    font-size: 48px;
    line-height: 110%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #464646;
    margin-top: 100px;
    margin-bottom: 80px;
    @media (max-width: 834px) {
        margin-top: 100px;
        margin-bottom: 60px;
        font-size: 32px;
        width: 80%;
  }
    @media (max-width: 375px) {
        margin-top: 120px;
        margin-bottom: 60px;
  }
`;

export const CarruselContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: minmax(16px, 1fr) minmax(834px, 1200px) minmax(16px,1fr);
    @media (max-width: 834px) {
        grid-template-columns: 24px minmax(0px, 834px) 24px;
    }
    @media (max-width: 375px) {
        grid-template-columns: 0px minmax(200px, 343px) 0px;
    }
`;

export const RowContentImg = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 48px 48px;
    justify-content: center;
    
    @media screen and (max-width: 834px) {
        gap: 30px 24px;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: antiquewhite;
`;

export const Content = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
`;

export const SwiperWrapper = styled.div`
    width: 80%;
    height: auto;
    margin: 0 auto;
    /* border: 1px solid #6844ED; */
    /* border-radius: 20px; */
    padding: 0px 39px;
    @media only screen and (max-width: 450px) {
        width: 100%;
        padding: 24px;
    }
`